import ScrollMagic from 'scrollmagic';

export function scrollTrigger() {
  const controller = new ScrollMagic.Controller();

  const fadeIn = document.querySelectorAll('.js-scrollAnimation');

  for (let i = 0; i < fadeIn.length; i++) {
    const tag = fadeIn[i];

    new ScrollMagic.Scene({
      triggerElement: tag,
      triggerHook: 'onEnter',
      offset: 200,
    })
      .setClassToggle(tag, 'is-animated')
      .addTo(controller);
  }
}
