import Swiper, { EffectFade, Autoplay, Navigation } from 'swiper';

// TOPページのMVで使用
export function slideHeroIndex() {
  const swiperHeroIndex = new Swiper('.js-slideIndexHero', {
    modules: [EffectFade, Autoplay],
    effect: 'fade',
    loop: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
  });
}

// TOPページのMV下にて使用
export function slideBnr() {
  const swiperIndexBnr = new Swiper('.js-slideBnr', {
    modules: [Autoplay, Navigation],
    loop: true,
    slidesPerView: 1.5,
    centeredSlides: true,
    spaceBetween: 15,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    navigation: {
      nextEl: '.js-slideBnrNext',
      prevEl: '.js-slideBnrPrev',
    },
    breakpoints: {
      768: {
        slidesPerView: 3.8,
        spaceBetween: 35,
      },
      1024: {
        slidesPerView: 3.8,
        spaceBetween: 45,
      },
    },
  });
}

// TOPページのBASICブランド
export function slideGalleryBasic() {
  const swiperGalleryBasic = new Swiper('.js-slideGalleryBasic', {
    modules: [EffectFade, Autoplay, Navigation],
    effect: 'fade',
    loop: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    navigation: {
      nextEl: '.js-slideGalleryBasicNext',
      prevEl: '.js-slideGalleryBasicPrev',
    },
  });
}

export function slideGalleryBasicSp() {
  const slideGalleryBasicSp = new Swiper('.js-slideGalleryBasicSp', {
    modules: [Autoplay],
    loop: true,
    slidesPerView: 1.2,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    spaceBetween: 20,
  });
}

// TOPページのELEGANCEブランド
export function slideGalleryElegance() {
  const swiperGalleryElegance = new Swiper('.js-slideGalleryElegance', {
    modules: [EffectFade, Autoplay, Navigation],
    effect: 'fade',
    loop: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    navigation: {
      nextEl: '.js-slideGalleryEleganceNext',
      prevEl: '.js-slideGalleryElegancePrev',
    },
  });
}

export function slideGalleryEleganceSp() {
  const slideGalleryEleganceSp = new Swiper('.js-slideGalleryEleganceSp', {
    modules: [Autoplay],
    loop: true,
    slidesPerView: 1.2,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    spaceBetween: 20,
  });
}

// TOPページのHIGH CLASSブランド
export function slideGalleryHighClass() {
  const swiperGalleryHighClass = new Swiper('.js-slideGalleryHighClass', {
    modules: [EffectFade, Autoplay, Navigation],
    effect: 'fade',
    loop: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    navigation: {
      nextEl: '.js-slideGalleryHighClassNext',
      prevEl: '.js-slideGalleryHighClassPrev',
    },
  });
}
export function slideGalleryHighClassSp() {
  const slideGalleryHighClassSp = new Swiper('.js-slideGalleryHighClassSp', {
    modules: [Autoplay],
    loop: true,
    slidesPerView: 1.2,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    spaceBetween: 20,
  });
}

// TOPページのNEWSにて使用
export function slideNews() {
  const swiperIndexNews = new Swiper('.js-slideNews', {
    modules: [Autoplay, Navigation],
    slidesPerView: 1.5,
    centeredSlides: true,
    spaceBetween: 15,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    loop: true,
    navigation: {
      nextEl: '.js-slideNewsNext',
      prevEl: '.js-slideNewsPrev',
    },
    breakpoints: {
      768: {
        slidesPerView: 3.8,
        spaceBetween: 30,
      },
      1024: {
        slidesPerView: 3.8,
        spaceBetween: 40,
      },
    },
  });
}

// BRANDページ下層のMVにて使用
export function slideBrandHero() {
  const swiperBrandHero = new Swiper('.js-slideBrandHero', {
    modules: [Autoplay],
    loop: true,
    slidesPerView: 1.5,
    centeredSlides: true,
    spaceBetween: 16,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
  });
}

// BRANDページ下層のMENUにて使用
export function slideMenu() {
  if (document.querySelector('.js-slideMenu')) {
    const slideLength = document.querySelectorAll('.js-slideMenu .swiper-slide').length;
    const slideWrapper = document.querySelector('.js-slideMenu .swiper-wrapper');
    const slideControls = document.querySelector('.js-slideMenu .js-slideControls');
    let loopFlag;

    if (slideLength > 1) {
      loopFlag = true;
    } else {
      loopFlag = false;
      slideWrapper.classList.add('is-single');
      slideControls.classList.add('is-hide');
    }

    const slideMenu = new Swiper('.js-slideMenu', {
      modules: [Autoplay, Navigation],
      loop: loopFlag,
      slidesPerView: 1.5,
      centeredSlides: true,
      spaceBetween: 16,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
      navigation: {
        nextEl: '.js-slideMenuNext',
        prevEl: '.js-slideMenuPrev',
      },
      breakpoints: {
        768: {
          slidesPerView: 3,
          spaceBetween: 32,
        },
      },
    });
  }
}

export function slideMenuOthers(FOOD_COAT_CLASS) {
  if (document.querySelector(FOOD_COAT_CLASS)) {
    const slideFoodCoat = document.querySelector(FOOD_COAT_CLASS);
    const slideLength = slideFoodCoat.querySelectorAll('.swiper-slide').length;
    const slideWrapper = slideFoodCoat.querySelector('.swiper-wrapper');
    const slideControls = slideFoodCoat.querySelector('.js-slideControls');
    const slideNext = slideFoodCoat.querySelector('.js-slideMenuNext');
    const slidePrev = slideFoodCoat.querySelector('.js-slideMenuPrev');
    let loopFlag;

    if (slideLength > 1) {
      loopFlag = true;
    } else {
      loopFlag = false;
      slideWrapper.classList.add('is-single');
      slideControls.classList.add('is-hide');
    }

    const slideMenuOthers = new Swiper(slideFoodCoat, {
      modules: [Autoplay, Navigation],
      loop: loopFlag,
      slidesPerView: 1.5,
      centeredSlides: true,
      spaceBetween: 16,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
      navigation: {
        nextEl: slideNext,
        prevEl: slidePrev,
      },
      breakpoints: {
        768: {
          slidesPerView: 3,
          spaceBetween: 32,
        },
      },
    });
  }
}
