import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export function scrollFadeUp() {
  gsap.utils.toArray('.js-fadeUp').forEach((el) => {
    gsap.from(el, {
      duration: 0.6,
      stagger: {
        from: 'start', //左側から
        amount: 0.4,
      },
      opacity: 0,
      y: 30,
      scrollTrigger: {
        trigger: el, // 対象物
        start: 'top 75%',
        once: false,
        markers: false,
      },
    });
  });
}

export function scrollFadeLeft() {
  gsap.utils.toArray('.js-fadeLeft').forEach((el) => {
    gsap.from(el, {
      duration: 0.6,
      stagger: {
        from: 'start', //左側から
        amount: 0.4,
      },
      opacity: 0,
      x: -30,
      scrollTrigger: {
        trigger: el, // 対象物
        start: 'top 75%',
        once: false,
        markers: false,
      },
    });
  });
}

export function scrollFadeRight() {
  gsap.utils.toArray('.js-fadeRight').forEach((el) => {
    gsap.from(el, {
      duration: 0.6,
      stagger: {
        from: 'start', //左側から
        amount: 0.4,
      },
      opacity: 0,
      x: 30,
      scrollTrigger: {
        trigger: el, // 対象物
        start: 'top 75%',
        once: false,
        markers: false,
      },
    });
  });
}
