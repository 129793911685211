import { scrollFadeRight, scrollFadeLeft, scrollFadeUp } from './modules/scrollFade';
import { scrollTrigger } from './modules/scrollTrigger';
import { loadingMovie } from './modules/loadingMovie';

/* ローディングアニメーション */
window.addEventListener('load', () => {
  loadingMovie();
});
/* end ローディングアニメーション */

/* fadeupアニメーション */
scrollFadeUp();
scrollFadeLeft();
scrollFadeRight();
/* end fadeupアニメーション */

/* scrollでclass付与 */
scrollTrigger();
/* end scrollでclass付与 */
