import { slideHeroIndex, slideBnr, slideGalleryBasic, slideGalleryElegance, slideGalleryHighClass, slideNews, slideGalleryBasicSp, slideGalleryEleganceSp, slideGalleryHighClassSp } from './slideConfig';
import { fadeView } from './fadeView';

export function loadingMovie() {
  // この関数はloading完了後にremoveされます
  // TOPページ限定で使用します

  const bodyEle = document.querySelector('body');
  const loadingWindow = document.querySelector('.js-loadingWindow');
  const loadingMovie = document.getElementById('loading');
  const IS_FIXED = 'is-fixed';
  let loadingPlayCount = 0;

  bodyEle.classList.add(IS_FIXED); // 背景固定用

  // 動画のループ再生
  loadingMovie.addEventListener('ended', () => {
    // loadingMovie.play();
  });

  setTimeout(() => {
    /* その他 */
    fadeView();
    /* end その他 */

    /** TOPページ用各スライダーを実行 */
    slideHeroIndex();
    slideBnr();
    slideGalleryBasic();
    slideGalleryBasicSp();
    slideGalleryElegance();
    slideGalleryEleganceSp();
    slideGalleryHighClass();
    slideGalleryHighClassSp();
    slideNews();
    /** end TOPページ用各スライダーを実行 */

    loadingWindow.classList.add('is-hide');
    bodyEle.classList.remove(IS_FIXED);

    setTimeout(() => {
      loadingWindow.remove();
      clearInterval(loadingEndClear);
    }, 1000);

    const loadingEndClear = setInterval(() => {
      if (loadingPlayCount == 0) {
        // ローディング完了後、数回再生させてローディング画面を削除
        loadingPlayCount++;
      } else {
        loadingMovie.addEventListener('ended', () => {
          /* その他 */
          fadeView();
          /* end その他 */

          /** TOPページ用各スライダーを実行 */
          slideHeroIndex();
          slideBnr();
          slideGalleryBasic();
          slideGalleryBasicSp();
          slideGalleryElegance();
          slideGalleryEleganceSp();
          slideGalleryHighClass();
          slideGalleryHighClassSp();
          slideNews();
          /** end TOPページ用各スライダーを実行 */

          loadingWindow.classList.add('is-hide');
          bodyEle.classList.remove(IS_FIXED);

          setTimeout(() => {
            loadingWindow.remove();
            clearInterval(loadingEndClear);
          }, 1000);
        });
      }
    }, 500);
  }, 2000);
}
